<template>
	<div class="berthplanner-container">
		<berth-planner-toolbar
			:BERTHPLANNER_SELECTORS="BERTHPLANNER_SELECTORS"
			:selectedDateModel="selectedDateModel"
			:statusItems="statusItems"
			:currentStatusItems="currentStatusItems"
			:readOnly="readOnly"
			:savingEnabled="savingEnabled"
			:userFunctionalities="userFunctionalities"
			:vessels="plannerModel"
		/>
		<d-berth-planner-diagram
			v-if="cartoReady && stopsLoaded && currentBerth"
			v-model="plannerModel"
			ref="dBerthPlannerDiagram"
			:currentPort="currentPort"
			:currentDock="currentDock"
			:currentBerth="currentBerth"
			:zoomFactor="zoomFactor"
			:lineUpByOperationTypeEnabled="lineUpByOperationTypeEnabled"
			:userFunctionalities="userFunctionalities"
			:statusItems="finalStatusItems"
			:readOnly="readOnly"
		/>
		<planner-legend
			legendType="berthplanner"
			:statusItems="finalStatusItems"
			:lineUpByOperationTypeEnabled="lineUpByOperationTypeEnabled"
			:savingEnabled="savingEnabled && !versioningEnabled"
			:userFunctionalities="userFunctionalities"
		/>
	</div>
</template>

<script>
import BerthPlannerToolbar from './toolbar/BerthPlannerToolbar.vue';
import DBerthPlannerDiagram from './DBerthPlannerDiagram.vue';
import PlannerLegend from './legend/PlannerLegend.vue';
import CartoMixin from '@/mixins/CartoMixin.js';

import FilterService from '@/services/filter.service.js';
import WeatherService from '@/services/weather.service.js';

export default {
	components: { BerthPlannerToolbar, DBerthPlannerDiagram, PlannerLegend },
	mixins: [CartoMixin],
	data() {
		return {
			BERTHPLANNER_SELECTORS: '', // [DOCK|BERTH|BOTH]
			modelName: 'berthplanner',
			selectedDateModel: this.getEmptySelectedDateModel(),
			plannerModel: this.getEmptyPlannerModel(),
			zoomFactor: 1,
			dailyForecast: [],
			stopsLoaded: null,
			statusItems: [],
			currentStatusItems: [],
			pelppoperationtypeItems: [], // REPSOL PERU
			lineUpByOperationTypeEnabled: true,
			savingEnabled: false,
			versioningEnabled: false,
			forecastInfoEnabled: window.localStorage.getItem('weatherForecastInBP') == 1,
			portAuthorityId: window.localStorage.getItem('workingPortAuthorityId')
		};
	},
	computed: {
		currentLocation: {
			get: function () {
				return this.$store.state.berthPlanner.configuration.currentLocation;
			},
			set: function (newValue) {
				this.$store.state.berthPlanner.configuration.currentLocation = newValue;
			}
		},
		currentPort: {
			get: function () {
				return this.currentLocation.currentPort;
			},
			set: function (newValue) {
				this.currentLocation.currentPort = newValue;
			}
		},
		currentDock: {
			get: function () {
				return this.currentLocation.currentDock;
			},
			set: function (newValue) {
				this.currentLocation.currentDock = newValue;
			}
		},
		currentBerth: {
			get: function () {
				return this.currentLocation.currentBerth;
			},
			set: function (newValue) {
				this.currentLocation.currentBerth = newValue;
			}
		},
		selectedDateModel_firstPlannerDay() {
			return (
				this.selectedDateModel &&
				new Date(
					this.selectedDateModel.startDate.getFullYear(),
					this.selectedDateModel.startDate.getMonth(),
					this.selectedDateModel.startDate.getDate() - 1
				)
			);
		},
		selectedDateModel_lastPlannerDay() {
			return this.selectedDateModel && this.selectedDateModel.endDate;
		},
		daysDifference() {
			return Math.round((this.selectedDateModel.endDate.getTime() - this.selectedDateModel.startDate.getTime()) / (1000 * 60 * 60 * 24) + 1);
		},
		minStringDate() {
			return this.convertDateToString(this.selectedDateModel_firstPlannerDay);
		},
		maxStringDate() {
			return this.convertDateToString(this.selectedDateModel_lastPlannerDay);
		},
		isRepsolPeru() {
			return this.currentLocation && this.currentLocation.currentDock && this.currentLocation.currentDock.description === 'REPSOL PERU';
		},
		finalStatusItems() {
			return this.isRepsolPeru && this.lineUpByOperationTypeEnabled ? this.pelppoperationtypeItems : this.statusItems;
		},

		//
		// PERMISOS
		//
		readOnly() {
			return !this.$route.path.includes('dberthplanner');
		},

		has_READ_STOP() {
			return this.$store.getters.hasFunctionality('READ_STOP');
		},
		has_EDIT_STOP() {
			return this.$store.getters.hasFunctionality('EDIT_STOP');
		},
		has_LIST_BERTHBLOCK() {
			return this.$store.getters.hasFunctionality('LIST_BERTHBLOCK');
		},
		has_INSERT_BERTHBLOCK() {
			return this.$store.getters.hasFunctionality('INSERT_BERTHBLOCK');
		},
		has_UPDATE_BERTHBLOCK() {
			return this.$store.getters.hasFunctionality('UPDATE_BERTHBLOCK');
		},
		has_READ_BOOKING() {
			return this.$store.getters.hasFunctionality('READ_BOOKING');
		},
		has_WRITE_BOOKING() {
			return this.$store.getters.hasFunctionality('WRITE_BOOKING');
		},
		has_CREATE_SUBSCRIPTION() {
			return this.$store.getters.hasFunctionality('CREATE_SUBSCRIPTION');
		},

		has_DETAIL_HIDE_ALERTS_BERTHING_PLAN() {
			return this.portAuthorityId == 474 || this.portAuthorityId == 12; // 474 -> Perú en DESA, 12 -> Perú en PRE
			//return this.$store.getters.hasFunctionality('has_DETAIL_HIDE_ALERTS_BERTHING_PLAN');
		},
		has_DETAIL_STOP_DATA_IN_BP() {
			return this.portAuthorityId == 474 || this.portAuthorityId == 12;
			//return this.$store.getters.hasFunctionality('DETAIL_STOP_DATA_IN _BP');
		},
		has_DETAIL_HIDE_BOLLARDS_IN_BP() {
			return this.portAuthorityId == 474 || this.portAuthorityId == 12;
			//return this.$store.getters.hasFunctionality('DETAIL_HIDE_BOLLARDS_IN_BP');
		},
		has_DETAIL_HIDE_KEEP_CHANGES_IN_BP() {
			return this.portAuthorityId == 474 || this.portAuthorityId == 12;
			//return this.$store.getters.hasFunctionality('DETAIL_HIDE_KEEP_CHANGES_IN_BP');
		},

		userFunctionalities() {
			return {
				has_READ_STOP: this.has_READ_STOP,
				has_EDIT_STOP: this.has_EDIT_STOP,
				has_LIST_BERTHBLOCK: this.has_LIST_BERTHBLOCK,
				has_INSERT_BERTHBLOCK: this.has_INSERT_BERTHBLOCK,
				has_UPDATE_BERTHBLOCK: this.has_UPDATE_BERTHBLOCK,
				has_READ_BOOKING: this.has_READ_BOOKING,
				has_WRITE_BOOKING: this.has_WRITE_BOOKING,
				has_CREATE_SUBSCRIPTION: this.has_CREATE_SUBSCRIPTION,
				has_DETAIL_HIDE_ALERTS_BERTHING_PLAN: this.has_DETAIL_HIDE_ALERTS_BERTHING_PLAN,
				has_DETAIL_STOP_DATA_IN_BP: this.has_DETAIL_STOP_DATA_IN_BP,
				has_DETAIL_HIDE_BOLLARDS_IN_BP: this.has_DETAIL_HIDE_BOLLARDS_IN_BP,
				has_DETAIL_HIDE_KEEP_CHANGES_IN_BP: this.has_DETAIL_HIDE_KEEP_CHANGES_IN_BP
			};
		}
	},
	watch: {
		dailyForecast: {
			deep: true,
			handler: function (v) {
				this.getPlannerModel();
			}
		},
		readonly(v) {
			this.getPlannerModel();
		},
		stopsLoaded(loaded) {
			this.$store.state.global.puiloader.show = !loaded;
		},
		currentLocation: {
			deep: true,
			handler: function (v) {
				if (this.isRepsolPeru) {
					this.getLineUpByOperationType();
				}

				if (v && v.currentPort && v.currentPort.id && this.stopsLoaded) {
					this.getPlannerModel();
				}
			}
		},
		async currentPort(v) {
			this.dailyForecast = await this.getDailyForecastFor7Days();
		}
	},
	async created() {
		this.dailyForecast = await this.getDailyForecastFor7Days();
	},
	async mounted() {
		this.savingEnabled = false;

		this.statusItems = this.getStatusItems();
		this.currentStatusItems = this.getDefaultStatus();
		this.BERTHPLANNER_SELECTORS = await this.getBERTHPLANNER_SELECTORS();

		this.selectedDateModel = this.getEmptySelectedDateModel();
		this.getPlannerModel();

		this.$puiEvents.$on('berthplanner-toolbar_auth', () => {
			console.log('berthplanner-toolbar_auth');
			this.savingEnabled = false;
			this.getPlannerModel();
		});

		this.$puiEvents.$on('berthplanner-toolbar_opt', (stops) => {
			if (stops) {
				this.getPlannerOpt(stops);
			} else {
				this.selectedDateModel = this.getEmptySelectedDateModel();
				this.getPlannerModel();
				this.versioningEnabled = false;
				this.savingEnabled = false;
			}

			this.stopsLoaded = false;
			this.savingEnabled = false;
			console.log('toolbar_optimizador', stops);

			this.plannerModel.stops = stops;
			this.stopsLoaded = true;
		});

		this.$puiEvents.$on('berthplanner-toolbar_versionSelected', (data) => {
			if (data && data.berthingjson) {
				this.selectedDateModel = {
					startDate: new Date(data.dateiniplan),
					endDate: new Date(data.datefinplan)
				};
				this.zoomFactor = (this.daysDifference + 1) / 7;
				this.getPlannerVersion(data);
			} else {
				this.selectedDateModel = this.getEmptySelectedDateModel();
				this.zoomFactor = (this.daysDifference + 1) / 7;
				this.getPlannerModel();
				this.versioningEnabled = false;
				this.savingEnabled = false;
			}
		});

		this.$puiEvents.$on('berthplanner-toolbar_plan', () => {
			this.savingEnabled = false;
			this.getPlannerModel();
		});

		this.$puiEvents.$on('berthplanner-toolbar_dateSelected', (selectedDateModel) => {
			this.$store.state.berthPlanner.configuration.selectedDateModel = selectedDateModel;
			this.selectedDateModel = selectedDateModel;
			this.zoomFactor = (this.daysDifference + 1) / 7;
			this.savingEnabled = false;
			this.getPlannerModel();
		});

		this.$puiEvents.$on('berthplanner-toolbar_zoomChanged', (zoomIncrement) => {
			if (zoomIncrement) {
				this.zoomFactor = this.zoomFactor + zoomIncrement;
			} else {
				this.zoomFactor = (this.daysDifference + 1) / 7;
			}
		});

		this.$puiEvents.$on('berthplanner-toolbar_statusSelected', (statusSelected) => {
			this.currentStatusItems = statusSelected;
			this.getPlannerModel();
		});

		this.$puiEvents.$on('berthplanner-toolbar_searchText', (searchText) => {
			this.$store.state.berthPlanner.configuration.searchText = searchText;
			this.searchText = searchText;
			this.getPlannerModel();
		});

		this.$puiEvents.$on('operationscountdown_reload', () => {
			this.getPlannerModel();
		});

		this.$puiEvents.$on('berthplanner-vesselDialog_update', (updatedStop) => {
			this.savingEnabled = true;
			this.plannerModel.stops.forEach((stop, index) => {
				if (stop.id == updatedStop.id) {
					updatedStop.etalocal = new Date(updatedStop.eta);
					updatedStop.etdlocal = new Date(updatedStop.etd);
					this.plannerModel.stops[index] = updatedStop;
				}
			});
			this.$refs.dBerthPlannerDiagram.setBoard();
		});

		this.$puiEvents.$on('berthplanner-legend_lineUpSelected', (lineUpByOperationTypeEnabled) => {
			this.lineUpByOperationTypeEnabled = lineUpByOperationTypeEnabled;
		});

		this.$puiEvents.$on('berthplanner-berthBlock_removal', (id) => {
			this.savingEnabled = true;
			this.plannerModel.blocks.forEach((block) => {
				if (block.id == id) {
					block.delete = true;
				}
			});
		});
	},
	destroyed() {
		this.$puiEvents.$off('berthplanner-toolbar_auth');
		this.$puiEvents.$off('berthplanner-toolbar_optimizador');
		this.$puiEvents.$off('berthplanner-toolbar_versionSelected');
		this.$puiEvents.$off('berthplanner-toolbar_plan');

		this.$puiEvents.$off('berthplanner-toolbar_dateSelected');
		this.$puiEvents.$off('berthplanner-toolbar_zoomChanged');
		this.$puiEvents.$off('berthplanner-toolbar_statusSelected');
		this.$puiEvents.$off('berthplanner-toolbar_searchText');

		this.$puiEvents.$off('berthplanner-operationscountdown_reload');
		this.$puiEvents.$off('berthplanner-vesselDialog_update');
		this.$puiEvents.$off('berthplanner-berthBlock_removal');
	},
	methods: {
		getEmptyPlannerModel() {
			return {
				days: [],
				berths: [],

				stops: [],
				blocks: [],
				bookings: [],
				bollards: []
			};
		},
		getEmptySelectedDateModel() {
			let today = new Date();
			let startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			let endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5); // 5 -> default range, also setted in updateStartDate && weather requests

			return {
				startDate: startDate,
				endDate: endDate
			};
		},
		getStatusItems() {
			let stopsStatusValues =
				this.$store.getters.stopsStatusValues && this.$store.getters.stopsStatusValues.filter((status) => status.visibleberthingplan);
			stopsStatusValues.sort((a, b) => {
				return a.orderby - b.orderby;
			});
			return stopsStatusValues || [];
		},
		getDefaultStatus() {
			let currentStatusItems = [];
			this.statusItems.forEach((item) => {
				currentStatusItems.push(item.stopstatuscode);
			});
			return currentStatusItems;
		},
		async getBERTHPLANNER_SELECTORS() {
			let BERTHPLANNER_SELECTORS = this.$store.state.berthPlanner.BERTHPLANNER_SELECTORS;

			if (BERTHPLANNER_SELECTORS == null) {
				await this.$puiRequests.getRequest(
					'/portauthorityvariable/get?variable=BERTHPLANNER_SELECTORS&portauthorityid=' + this.portAuthorityId,
					null,
					(response) => {
						BERTHPLANNER_SELECTORS = response.data.value;
					},
					(error) => {
						BERTHPLANNER_SELECTORS = 'BERTH';
					}
				);
			}
			return BERTHPLANNER_SELECTORS;
		},
		async setPlannerModel(plannerModel) {
			this.stopsLoaded = false;

			// revisar toda esta parte a partir del json, y ver funcionamiento con berths
			const portid = this.currentDock && this.currentDock.portid ? this.currentDock.portid : 0;
			const dockid = this.currentDock.id;

			this.plannerModel.days = this.getPlannerDays();

			plannerModel.berths = await this.getBerthsByDock(dockid);

			for (let i = 0; i < plannerModel.berths.length; i++) {
				plannerModel.berths[i].correlativeberth = this.currentDock && this.currentDock.correlativeberths;
			}

			let dockBollards = await this.getDockBollards(portid, dockid, plannerModel.berths);
			plannerModel.bollards = dockBollards;

			this.plannerModel.berths = plannerModel.berths;
			this.plannerModel.bollards = plannerModel.bollards;
			this.plannerModel.stops = plannerModel.stops;
			this.plannerModel.blocks = plannerModel.blocks;
			this.plannerModel.bookings = plannerModel.bookings;

			this.stopsLoaded = true;
		},
		async getPlannerModel() {
			this.stopsLoaded = false;
			this.plannerModel = this.getEmptyPlannerModel();
			this.plannerModel.days = this.getPlannerDays();

			if (!this.currentDock && !this.currentBerth) {
				setTimeout(() => {
					this.$refs.dBerthPlannerDiagram && this.$refs.dBerthPlannerDiagram.setBoard();
				}, 1000);
				this.stopsLoaded = true;
				return;
			}

			if (this.currentDock && this.currentDock.id) {
				const portid = this.currentDock && this.currentDock.portid ? this.currentDock.portid : 0;
				const dockid = this.currentDock && this.currentDock.id ? this.currentDock.id : 0;

				let dockModel = await this.getDockModel(portid, dockid);
				this.plannerModel.bollards = dockModel.bollards;
				this.plannerModel.berths = dockModel.berths;
				this.plannerModel.stops = dockModel.stops;
				this.plannerModel.blocks = dockModel.blocks;
				this.plannerModel.bookings = dockModel.bookings;

				this.stopsLoaded = true;
				return;
			}

			if (this.currentBerth && this.currentBerth.id) {
				const portid = this.currentBerth && this.currentBerth.portid ? this.currentBerth.portid : 0;
				const berthid = this.currentBerth && this.currentBerth.id ? this.currentBerth.id : 0;

				let berthModel = await this.getBerthModel(portid, berthid);
				this.plannerModel.bollards = berthModel.bollards;
				this.plannerModel.stops = berthModel.stops;
				this.plannerModel.blocks = berthModel.blocks;
				this.plannerModel.bookings = berthModel.bookings;

				this.stopsLoaded = true;
				return;
			}

			this.stopsLoaded = true;
		},
		async getPlannerOpt(stops) {
			const portid = this.currentBerth && this.currentBerth.portid ? this.currentBerth.portid : 0;
			let locationid = 0;
			if (this.BERTHPLANNER_SELECTORS == 'BERTH') {
				locationid = this.currentBerth && this.currentBerth.id ? this.currentBerth.id : 0;
			} else {
				locationid = this.currentDock && this.currentDock.id ? this.currentDock.id : 0;
			}

			let versionedPlannerModel = this.getEmptyPlannerModel();
			versionedPlannerModel.stops = this.fillStopsFromResponse(stops);

			let dockModel = await this.getDockModel(portid, locationid);
			versionedPlannerModel.blocks = dockModel.blocks;
			versionedPlannerModel.bookings = dockModel.bookings;

			this.setPlannerModel(versionedPlannerModel);
			this.versioningEnabled = true;
		},
		async getPlannerVersion(data) {
			const portid = this.currentBerth && this.currentBerth.portid ? this.currentBerth.portid : 0;
			let locationid = 0;
			if (this.BERTHPLANNER_SELECTORS == 'BERTH') {
				locationid = this.currentBerth && this.currentBerth.id ? this.currentBerth.id : 0;
			} else {
				locationid = this.currentDock && this.currentDock.id ? this.currentDock.id : 0;
			}
			let versionedPlannerModel = this.getEmptyPlannerModel();
			versionedPlannerModel.stops = this.fillStopsFromResponse(data.berthingjson.data);

			let dockModel = await this.getDockModel(portid, locationid);
			versionedPlannerModel.blocks = dockModel.blocks;
			versionedPlannerModel.bookings = dockModel.bookings;
			this.setPlannerModel(versionedPlannerModel);

			this.versioningEnabled = true;
		},
		getPlannerDays() {
			// get day range
			const days = [];

			for (let i = 0; i <= this.daysDifference; i++) {
				let newDay = new Date(this.selectedDateModel_firstPlannerDay);

				if (i > 0) {
					newDay.setDate(newDay.getDate() + i);
				}
				const forecastInfoOperations = this.getForecastModel(newDay);

				let day = {
					date: newDay,
					weekDayLabel: this.$t(`common.weekday.${newDay.getDay()}`),
					dayLabel: `${newDay.getDate()}/${newDay.getMonth() + 1}`,
					forecastInfo: forecastInfoOperations,
					locale: this.$store.getters.getUserLanguage
				};

				days.push({ ...day });
			}

			return days;
		},
		getForecastModel(date) {
			if (this.dailyForecast && Array.isArray(this.dailyForecast) && this.dailyForecast.length > 0) {
				for (let i = 0; i < this.dailyForecast.length; i++) {
					const dayForecast = this.dailyForecast[i];
					const compDate = new Date(dayForecast.date);

					let forecastInfoOperations = {};

					if (
						date.getDate() === compDate.getDate() &&
						date.getMonth() === compDate.getMonth() &&
						date.getFullYear() === compDate.getFullYear()
					) {
						forecastInfoOperations.windSpeed = `${Math.round(dayForecast.maxwind_kph * 10) / 10} kph`; // ${Math.round(dayForecast.maxwind_mph * 10) / 10} mph
						const avgWindDegrees = dayForecast.wind_degrees.reduce((a, b) => a + b, 0) / dayForecast.wind_degrees.length;
						forecastInfoOperations.windDirection = WeatherService.getWindDirectionFromWindDegrees(avgWindDegrees);
						forecastInfoOperations.temperatureLabel = `${Math.round(dayForecast.avgtemp_c * 10) / 10} ºC`;
						forecastInfoOperations.windLabel = `${forecastInfoOperations.windSpeed} (${forecastInfoOperations.windDirection})`;

						if (dayForecast.tides && Array.isArray(dayForecast.tides)) {
							const maxTide = dayForecast.tides.reduce((max, tide) => {
								const tideHeight = parseFloat(tide.tide_height_mt);
								return tideHeight > max ? tideHeight : max;
							}, -Infinity);
							forecastInfoOperations.maxTideLabel = `${maxTide} m`;
						} else {
							forecastInfoOperations.maxTideLabel = '';
						}

						return forecastInfoOperations;
					}
				}
			}

			return null;
		},
		async getDailyForecastFor7Days() {
			if (this.currentPort && this.currentPort.latitude && this.currentPort.longitude && this.forecastInfoEnabled) {
				const forecastdays = await WeatherService.getDailyForecast(this.currentPort.latitude, this.currentPort.longitude, 'forecast');
				const marinedays = await WeatherService.getDailyForecast(this.currentPort.latitude, this.currentPort.longitude, 'marine');
				const forecast = forecastdays.map((forecastday) => {
					return {
						maxwind_kph: forecastday.day.maxwind_kph,
						maxwind_mph: forecastday.day.maxwind_mph,
						date: forecastday.date,
						avgtemp_c: forecastday.day.avgtemp_c,
						wind_degrees: forecastday.hour.map((a) => a.wind_degree)
					};
				});
				const tides = marinedays.map((marineday) => {
					return {
						date: marineday.date,
						tides: marineday.day.tides
					};
				});
				forecast.forEach((dayForecast) => {
					const matchingTide = tides.find((tide) => tide.date === dayForecast.date);
					if (matchingTide) {
						dayForecast.tides = matchingTide.tides[0].tide;
					}
				});
				return forecast;
			} else {
				return null;
			}
		},
		async getDockModel(portid, dockid) {
			let dockModel = this.getEmptyPlannerModel();
			dockModel.berths = await this.getBerthsByDock(dockid);

			let locationIds = [];
			for (let i = 0; i < dockModel.berths.length; i++) {
				let berthid = dockModel.berths[i].id;
				dockModel.berths[i].correlativeberth = this.currentDock && this.currentDock.correlativeberths;
				locationIds.push(berthid);

				//let bollards = await this.getBerthBollards(portid, dockid, berthid);
				//dockModel.bollards = dockModel.bollards.concat(bollards);
				//dockModel.berths[i].bollardsLength = bollards.length;
			}

			let dockBollards = await this.getDockBollards(portid, dockid, dockModel.berths);
			dockModel.bollards = dockBollards;

			//console.log('bollards', dockModel.bollards);
			//console.log('dockBollards', dockBollards);

			dockModel.stops = await this.getBerthStops(portid, locationIds);
			dockModel.blocks = await this.getBerthBlocks(locationIds);
			dockModel.bookings = await this.getBookings(locationIds);

			for (let i = 0; i < dockModel.berths.length; i++) {
				for (let j = 0; j < dockModel.stops.length; j++) {
					if (dockModel.berths[i].id == dockModel.stops[j].berthid) {
						dockModel.stops[j].isTerminal = dockModel.berths[i].initbol == null && dockModel.berths[i].endbol == null;
					}
				}
			}

			return dockModel;
		},
		async getBerthsByDock(dockid) {
			let berths = [];

			await this.$puiRequests.getRequest(
				'/berth/getBerthsByDock?id=' + dockid,
				null,
				(response) => {
					berths = response.data;
					berths.sort((a, b) => {
						return a.orderindock - b.orderindock;
					});
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			return berths;
		},
		async getDockBollards(portid, dockid, berths) {
			let dockBollards = [];

			await this.$puiRequests.getRequest(
				this.$store.getters.getModelByName('dock').url.getBollardsByDock,
				{
					portid: portid,
					dockid: dockid
				},
				(response) => {
					dockBollards = response.data;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			let bollards = [];

			for (let i = 0; i < berths.length; i++) {
				let berth = berths[i];

				if (berth.initbol == null && berth.endbol == null) {
					bollards.push(this.getBerthLimit('start', portid, dockid, berths[i].id));
					bollards.push(this.getBerthLimit('end', portid, dockid, berths[i].id));
				} else {
					let berthBollards = dockBollards.filter((bollard) => bollard.berthid == berths[i].id);
					let orderedBerthBollards = berthBollards.sort((a, b) => a.orderby - b.orderby);

					orderedBerthBollards[0].berthlimit = true;
					orderedBerthBollards[orderedBerthBollards.length - 1].berthlimit = true;

					bollards = bollards.concat(orderedBerthBollards);
				}

				berths[i].bollardsLength = bollards.length;
			}

			return bollards;
		},
		async getBerthModel(portid, berthid) {
			let locationIds = [berthid];
			let bollards = await this.getBerthBollards(portid, null, berthid);
			let stops = await this.getBerthStops(portid, locationIds);
			let blocks = await this.getBerthBlocks(locationIds);
			let bookings = await this.getBookings(locationIds);

			return {
				bollards: bollards,
				stops: stops,
				blocks: blocks,
				bookings: bookings
			};
		},
		async getBerthBollards(portid, dockid, berthid) {
			let bollards = [];

			await this.$puiRequests.getRequest(
				this.$store.getters.getModelByName('berth').url.getBollardsByBerth,
				{
					portid: portid,
					berthid: berthid
				},
				(response) => {
					bollards = response.data;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			if (bollards.length > 0) {
				bollards[0].berthlimit = true;
				bollards[bollards.length - 1].berthlimit = true;
			}

			if (bollards.length == 0) {
				bollards.push(this.getBerthLimit('start', portid, dockid, berthid));
				bollards.push(this.getBerthLimit('end', portid, dockid, berthid));
			}

			return bollards;
		},
		getBerthLimit(bolcode, portid, dockid, berthid) {
			return {
				id: null,
				portid: portid,
				dockid: dockid,
				berthid: berthid,
				berthlimit: true,
				bolcode: bolcode + '_' + berthid
			};
		},
		async getBerthStops(portid, locationIds) {
			let berthStops = [];

			if (portid == null) {
				return berthStops;
			}

			const filter = {
				groupOp: 'and',
				groups: FilterService.getBerthPlannerFilterGroups(
					this.minStringDate,
					this.maxStringDate,
					locationIds,
					portid,
					this.currentStatusItems
				),
				rules: []
			};

			const params = {
				model: this.modelName,
				page: 1,
				queryLang: this.$store.getters.getUserLanguage,
				rows: 100,
				filter: filter
			};

			if (this.orderingBy) {
				params.order = [{ column: this.orderingBy, direction: 'asc' }];
			}

			if (this.searchText) {
				params.queryText = this.searchText;
				params.queryFields = ['vesselname', 'portcallnumber'];
			}

			await this.$puiRequests.postRequest(
				'/vstopberthplanner/getList',
				params,
				(response) => {
					try {
						berthStops = this.fillStopsFromResponse(response.data.data);
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			return berthStops;
		},
		fillStopsFromResponse(data) {
			let stops = [];

			if (data && data.length > 0) {
				for (let i = 0, length = data.length; i < length; i++) {
					const stop = data[i];

					stop.idstop = parseInt(stop.id);

					stop.etalocal = stop.etaplanner ? new Date(stop.etaplanner) : new Date(stop.eta);
					stop.etdlocal = stop.etaplanner ? new Date(stop.etdplanner) : new Date(stop.etd);
					stop.delayed = stop.delayeta || stop.delayetc ? 'DELAYED' : undefined;
					stop.lessthantwentyfourtoetaNegated = !stop.lessthantwentyfourtoeta;
					stop.etalocal = stop.etaterminal ? new Date(stop.etaterminal) : stop.eta ? new Date(stop.eta) : undefined;
					stop.etdlocal = stop.etdterminal ? new Date(stop.etdterminal) : stop.etd ? new Date(stop.etd) : undefined;
					stop.etclocal = stop.etc ? new Date(stop.etc) : undefined;
					stop.rtslocal = stop.rts ? new Date(stop.rts) : undefined;
					stop.atalocal = stop.ataterminal ? new Date(stop.ataterminal) : stop.ata ? new Date(stop.ata) : undefined;
					stop.atdlocal = stop.atdterminal ? new Date(stop.atdterminal) : stop.atd ? new Date(stop.atd) : undefined;
					stop.atclocal = stop.atc ? new Date(stop.atc) : undefined;
					stop.ninetyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -90);
					stop.sixtyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -60);
					stop.thirtyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -30);

					if (stop.ninetyminstoetc || stop.sixtyminstoetc || stop.thirtyminstoetc) {
						console.log('Alert: ETC near to reach', stop.vesselname, stop.ninetyminstoetc, stop.sixtyminstoetc, stop.thirtyminstoetc);
					}

					const status = this.statusItems.filter((status) => status.stopstatuscode == stop.statusid);
					status[0] && (stop.statusColor = status[0].colorhex);
					status[0] && (stop.statusOutlineColor = status[0].colorhexoutline);

					if (this.currentStatusItems.includes(stop.statusid)) {
						stops.push(stop);
					}
				}
			}

			return stops;
		},
		async getBerthBlocks(locationIds) {
			let berthBlocks = [];

			if (this.userFunctionalities.has_LIST_BERTHBLOCK) {
				const berthblockfilter = {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'locationid', op: 'in', data: locationIds },
						{ field: 'dateini', op: 'ge', data: this.minStringDate },
						{ field: 'dateend', op: 'le', data: this.maxStringDate }
					]
				};

				await this.$puiRequests.postRequest(
					'/berthblock/list',
					{
						filter: berthblockfilter,
						rows: -1
					},
					(response) => {
						try {
							if (response.data && response.data.data && response.data.data.length > 0) {
								for (let i = 0, length = response.data.data.length; i < length; i++) {
									berthBlocks.push(response.data.data[i]);
								}
							}
						} catch (error) {
							console.error(error);
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}

			return berthBlocks;
		},
		async getBookings(locationIds) {
			let bookings = [];

			if (this.userFunctionalities.has_READ_BOOKING) {
				const bookingfilter = {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'berth', op: 'in', data: locationIds },
						{ field: 'eta', op: 'ge', data: this.minStringDate },
						{ field: 'etd', op: 'le', data: this.maxStringDate }
					]
				};

				await this.$puiRequests.postRequest(
					'/booking/list',
					{
						filter: bookingfilter,
						rows: -1
					},
					(response) => {
						try {
							if (response.data && response.data.data && response.data.data.length > 0) {
								bookings = this.fillBookingsFromResponse(response);
							}
						} catch (error) {
							console.error(error);
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}

			return bookings;
		},
		fillBookingsFromResponse(response) {
			let bookings = [];

			if (response.data && response.data.data && response.data.data.length > 0) {
				for (let i = 0, length = response.data.data.length; i < length; i++) {
					const booking = response.data.data[i];

					booking.idstop = parseInt(booking.id);
					booking.stop = parseInt(booking.id);
					booking.bollardini = parseFloat(booking.bolini);
					booking.bollardend = parseFloat(booking.bolend);
					booking.bollardinicode = parseFloat(booking.bolini);
					booking.bollardendcode = parseFloat(booking.bolend);

					booking.vesselname = booking.shipname ? booking.shipname : '';
					booking.vesseltype = booking.shiptypename ? booking.shiptypename : '';

					booking.delayed = undefined;
					booking.etaplannerlocal = new Date(booking.eta);
					booking.etdplannerlocal = new Date(booking.etd);
					booking.etalocal = booking.eta ? new Date(booking.eta) : undefined;
					booking.etdlocal = booking.etd ? new Date(booking.etd) : undefined;
					booking.etclocal = booking.etc ? new Date(booking.etc) : undefined;
					booking.rtslocal = booking.rts ? new Date(booking.rts) : undefined;
					booking.atalocal = booking.ata ? new Date(booking.ata) : undefined;
					booking.atdlocal = booking.atd ? new Date(booking.atd) : undefined;
					booking.atclocal = booking.atc ? new Date(booking.atc) : undefined;

					bookings.push(booking);
				}
			}

			return bookings;
		},
		async getLineUpByOperationType() {
			const opts = {
				model: 'pelppoperationtype',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: []
				}
			};

			await this.$puiRequests.postRequest('/puisearch', opts, async (response) => {
				this.pelppoperationtypeItems = [];

				if (response && response.data && response.data.data) {
					response.data.data.forEach((operation) => {
						if (this.pelppoperationtypeItems.filter((op) => op.bptype == operation.bptype).length == 0) {
							this.pelppoperationtypeItems.push(operation);
						}
					});
				}
			});
		},
		convertDateToString(date) {
			if (date) {
				const addZero = function (num) {
					return num < 10 ? '0' + num : num;
				};
				return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
			}
			return null;
		},
		isStopEtcMinusXMinutesLowerThanNow(stop, xMinutes) {
			if (!stop || !stop.etc || stop.status !== 'INITIATED' || stop.atc) {
				return false;
			}
			const now = new Date();
			const etc = new Date(stop.etc);
			if (etc.getTime() < now.getTime()) {
				return false;
			}
			const etcMinusXMinutes = new Date(etc.getTime() + xMinutes * 60000);
			return etcMinusXMinutes.getTime() <= now.getTime();
		},

		// -------------------

		back() {
			this.savingEnabled = false;
			this.getPlannerModel();
		},
		async save() {
			await this.plannerModel.stops.forEach((stop) => {
				new Promise(async (resolve) => {
					await this.$puiRequests.patchRequest(
						'/stop/patch?id=' + stop.id,
						{
							bollardiniid: stop.bollardiniid,
							bollardendid: stop.bollardendid,

							berthingtype: stop.berthingtypecode,
							berthid: stop.berthid,

							startdate: stop.eta,
							enddate: stop.etd
						},
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							resolve(true);
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
							resolve(false);
						}
					);
				});
			});

			await this.plannerModel.blocks.forEach((block) => {
				let requestBerthBlock = Object.assign({}, block);
				requestBerthBlock.bollardini = requestBerthBlock.bollardiniid;
				requestBerthBlock.bollardend = requestBerthBlock.bollardendid;

				new Promise(async (resolve) => {
					if (requestBerthBlock.delete != true) {
						await this.$puiRequests.patchRequest(
							'/berthblock/patch?id=' + requestBerthBlock.id,
							requestBerthBlock,
							() => {
								this.$puiNotify.success(this.$t('pui9.save.success'));
							},
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
								resolve(false);
							}
						);
					} else {
						await this.$puiRequests.deleteRequest(
							'/berthblock/delete?id=' + requestBerthBlock.id,
							null,
							() => {
								this.$puiNotify.success(this.$t('pui9.save.success'));
							},
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
								resolve(false);
							}
						);
					}

					resolve(true);
				});
			});
			await this.plannerModel.bookings.forEach((booking) => {
				new Promise(async (resolve) => {
					await this.$puiRequests.patchRequest(
						'/booking/patch?id=' + booking.id,
						{
							bollardiniid: booking.bollardiniid,
							bollardendid: booking.bollardendid,

							berth: booking.berth,

							eta: booking.eta,
							etd: booking.etd
						},
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							resolve(true);
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
							resolve(false);
						}
					);
				});
			});

			this.savingEnabled = false;

			// patch stop -> stop allocation -> v_stop_berthplanner ~ 150/200 ms

			setTimeout(() => {
				this.getPlannerModel();
			}, 2200);
		}
	}
};
</script>

<style lang="postcss">
html:has(.berthplanner-container) {
	scrollbar-width: auto !important;
}
</style>
