<template>
	<v-layout align-center wrap class="berthplanner-toolbar pa-0">
		<v-col class="d-flex justify-start">
			<berth-planner-toolbar-date-selector
				ref="berthPlannerToolbarDateSelector"
				v-model="internalDateModel"
				:disabled="currentVersionLoaded"
				:savingEnabled="savingEnabled"
				@input="updateSelectedDateModel"
				range
			/>
			<pui-select
				ref="portSelect"
				v-show="showPortSelect"
				v-model="currentPort"
				modelName="port"
				itemValue="id"
				itemText="portname"
				:itemsToSelect="itemsToSelectPort"
				:placeholder="$t('berthplanner.port')"
				@input="countPorts"
				singleItemAutoselect
				return-object
				hide-details
				clearable
				toplabel
				reactive
				solo
				flat
			></pui-select>
			<pui-select
				v-if="BERTHPLANNER_SELECTORS == 'DOCK' || BERTHPLANNER_SELECTORS == 'BOTH'"
				v-model="currentDock"
				modelName="dock"
				itemValue="id"
				itemText="description"
				:itemsToSelect="itemsToSelectDock"
				:fixedFilter="dockFilter"
				:placeholder="$t('berthplanner.dock')"
				singleItemAutoselect
				return-object
				hide-details
				clearable
				toplabel
				reactive
				solo
				flat
			></pui-select>
			<pui-select
				v-if="BERTHPLANNER_SELECTORS == 'BERTH' || BERTHPLANNER_SELECTORS == 'BOTH'"
				v-model="currentBerth"
				modelName="berth"
				itemValue="id"
				:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
				:itemsToSelect="itemsToSelectBerth"
				:fixedFilter="berthFilter"
				:placeholder="$t('berthplanner.berth')"
				singleItemAutoselect
				return-object
				hide-details
				clearable
				toplabel
				reactive
				solo
				flat
			></pui-select>
			<pui-select
				v-if="$store.getters.getSession.functionalities.includes('ACTION_VERSION_BERTHINGPLAN')"
				v-model="currentVersion"
				modelName="berthingplanversion"
				itemValue="id"
				itemText="description"
				:itemsToSelect="itemsToSelectVersion"
				:fixedFilter="versionFilter"
				:placeholder="$t('berthplanner.version')"
				return-object
				hide-details
				clearable
				toplabel
				reactive
				solo
				flat
			></pui-select>
			<v-text-field
				class="searchText"
				:placeholder="$t('operationstoolbar.search')"
				v-model="searchText"
				prepend-inner-icon="far fa-search"
				hide-details
				outlined
				clearable
				solo
				flat
			></v-text-field>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" class="ml-4" v-bind="attrs" v-on="on">{{ $t('berthplanner.additionalactions') }}</v-btn>
				</template>
				<v-list>
					<v-list-item>
						<v-btn color="primary" text @click.stop="dialog = true">
							{{ $t('berthplanner.statusfilter') }}
						</v-btn>
						<v-dialog v-model="dialog" max-width="500">
							<v-card>
								<v-card-title class="text-h5">{{ $t('berthplanner.statusfilter') }}</v-card-title>
								<v-card-text>
									<v-checkbox
										v-for="(statusItem, index) in statusItems"
										v-model="currentStatusModel"
										:label="statusItem.stopstatusname.charAt(0) + statusItem.stopstatusname.slice(1).toLowerCase()"
										:value="statusItem.stopstatuscode"
										:key="index"
									/>
								</v-card-text>
								<v-divider></v-divider>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="primary" text @click="dialog = false">{{ $t('pui9.accept') }}</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-list-item>
					<v-list-item v-if="$store.getters.getSession.functionalities.includes('ACTION_VIEW_OUT_RANGE_IN_BP')">
						<v-btn color="secondary" text @click.stop="$refs.berthPlannerWrongBollardsGrid.visible = true" :disabled="stops.length === 0">
							{{ $t('berthplanner.vesselsoutofrange') }}
						</v-btn>
						<berth-planner-wrong-bollards-dialog ref="berthPlannerWrongBollardsGrid" :stops="stops" />
					</v-list-item>
					<v-list-item v-if="userFunctionalities.has_INSERT_BERTHBLOCK">
						<v-btn color="primary" text @click.stop="berthBlockVisibleDialog = true">
							{{ $t('berthplanner.berthblock') }}
						</v-btn>
						<berth-planner-berth-block-dialog
							v-if="berthBlockVisibleDialog"
							v-model="berthBlockVisibleDialog"
							:currentBerth="currentBerth"
							:berthFilter="berthFilter"
							:userFunctionalities="userFunctionalities"
						/>
					</v-list-item>
					<v-list-item v-if="$store.getters.getSession.functionalities.includes('ACTION_VIEW_METEO_IN_BP')">
						<v-btn
							color="primary"
							text
							@click.stop="$refs.berthplannerWeatherinfoDialog.visible = true"
							:disabled="!isWeatherValidDate()"
						>
							{{ $t('berthplanner.weatherInfo') }}
						</v-btn>
						<berth-planner-weatherinfo-dialog ref="berthplannerWeatherinfoDialog" :currentPort="currentPort" />
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_AUTHORIZE_STOP')">
						<v-btn color="primary" text @click.stop="authorizeStops()">
							{{ $t('berthplanner.authorize') }}
						</v-btn>
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_PLAN_STOP')">
						<v-btn color="primary" text @click.stop="planStops()">
							{{ $t('berthplanner.plan') }}
						</v-btn>
					</v-list-item>
					<v-list-item v-if="!readOnly">
						<v-btn color="primary" text @click.stop="optimize()">
							{{ $t('berthplanner.optimize.title') }}
						</v-btn>
						<berth-planner-optimization-dialog ref="berthPlannerOptimizationDialog" />
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_DIALOG_WAITING_ETA_COST')">
						<v-btn color="primary" text @click.stop="waitingEtaCost()">
							{{ $t('berthplanner.waitingEtaCost.title') }}
						</v-btn>
						<berth-planner-waiting-eta-cost-dialog ref="berthPlannerWaitingEtaCostDialog" />
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_VERSION_BERTHINGPLAN')">
						<v-btn color="primary" text @click.stop="saveVersion()">
							{{ $t('berthplanner.saveVersion.title') }}
						</v-btn>
						<berth-planner-save-version-dialog ref="berthPlannerSaveVersionDialog" />
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_VALIDATE_COST')">
						<v-btn color="primary" text @click.stop="validateCost()">
							{{ $t('berthplanner.validateCost.title') }}
						</v-btn>
						<berth-planner-validate-cost-dialog ref="berthPlannerValidateCostDialog" />
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_CONFIRM_VERSION_BERTHINGPLAN')">
						<v-btn color="primary" text @click.stop="confirmVersion()">
							{{ $t('berthplanner.confirmVersion.title') }}
						</v-btn>
						<berth-planner-confirm-version-dialog ref="berthPlannerConfirmVersionDialog" />
					</v-list-item>
				</v-list>
			</v-menu>

			<v-btn color="error" class="ml-4" @click="currentVersion = {}" v-if="currentVersionLoaded">
				{{ $t('operationstoolbar.versionLoaded') }}
			</v-btn>

			<v-container class="pa-0 d-flex justify-end mr-4">
				<v-btn x-small outlined @click="updateZoom(0.3)" class="pa-3 mt-2"><v-icon color="dark-grey" small>fas fa-plus</v-icon></v-btn>
				<v-btn x-small outlined @click="updateZoom()" class="pa-3 mt-2"><v-icon color="dark-grey" small>fas fa-search</v-icon></v-btn>
				<v-btn x-small outlined @click="updateZoom(-0.3)" class="pa-3 mt-2"><v-icon color="dark-grey" small>fas fa-minus</v-icon></v-btn>
			</v-container>
		</v-col>
		<planner-toolbar-legend legendType="berthplanner" class="pr-4" />
		<authorize-dialog :dialog="dialogAuthorize" :stopsToAuthorize="stopsToAuthorize" @closeAuthorization="dialogAuthorize = false" />
	</v-layout>
</template>

<script>
import BerthPlannerToolbarDateSelector from './BerthPlannerToolbarDateSelector.vue';
import BerthPlannerWrongBollardsDialog from './BerthPlannerWrongBollardsDialog.vue';
import BerthPlannerWeatherinfoDialog from './BerthPlannerWeatherinfoDialog.vue';
import BerthPlannerOptimizationDialog from './BerthPlannerOptimizationDialog.vue';
import BerthPlannerValidateCostDialog from './BerthPlannerValidateCostDialog.vue';
import BerthPlannerWaitingEtaCostDialog from './BerthPlannerWaitingEtaCostDialog.vue';
import BerthPlannerSaveVersionDialog from './BerthPlannerSaveVersionDialog.vue';
import BerthPlannerConfirmVersionDialog from './BerthPlannerConfirmVersionDialog.vue';
import BerthPlannerBerthBlockDialog from '../dialogs/BerthPlannerBerthBlockDialog.vue';
import PlannerToolbarLegend from '../legend/PlannerToolbarLegend.vue';
import { trackEvent, trackDownload } from '@/plugins/matomo';
import Stopmodals from '@/components/stop/StopModals.vue';
import AuthorizeDialog from '@/components/operations/map/sections/infoperiods/requesteds/AuthorizeDialog.vue';

export default {
	components: {
		BerthPlannerToolbarDateSelector,
		BerthPlannerWrongBollardsDialog,
		BerthPlannerBerthBlockDialog,
		BerthPlannerWeatherinfoDialog,
		PlannerToolbarLegend,
		Stopmodals,
		AuthorizeDialog,
		BerthPlannerOptimizationDialog,
		BerthPlannerWaitingEtaCostDialog,
		BerthPlannerSaveVersionDialog,
		BerthPlannerValidateCostDialog,
		BerthPlannerConfirmVersionDialog
	},
	props: {
		BERTHPLANNER_SELECTORS: {
			type: String,
			required: true
		},
		selectedDateModel: {
			type: Object,
			required: true
		},
		statusItems: {
			type: Array,
			required: true
		},
		currentStatusItems: {
			type: Array,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: true
		},
		savingEnabled: {
			type: Boolean,
			required: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		},
		vessels: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			currentStatusModel: this.currentStatusItems,
			searchText: '',
			stops: [],
			stopsKey: 1,
			dialog: false,
			dialogMeteo: false,
			showPortSelect: window.localStorage.getItem('workingPortsId').split(',').length > 1,
			internalDateModel: this.selectedDateModel,
			dialogAuthorize: false,
			stopsToAuthorize: [],
			berthBlockVisibleDialog: false,
			currentVersion: {}
		};
	},
	computed: {
		currentLocation: {
			get: function () {
				return this.$store.state.berthPlanner.configuration.currentLocation;
			},
			set: function (newValue) {
				this.$store.state.berthPlanner.configuration.currentLocation = newValue;
			}
		},
		currentPort: {
			get: function () {
				return this.currentLocation.currentPort;
			},
			set: function (newValue) {
				this.currentLocation.currentPort = newValue;
			}
		},
		currentDock: {
			get: function () {
				return this.currentLocation.currentDock;
			},
			set: function (newValue) {
				this.currentLocation.currentDock = newValue;
			}
		},
		currentBerth: {
			get: function () {
				return this.currentLocation.currentBerth;
			},
			set: function (newValue) {
				this.currentLocation.currentBerth = newValue;
			}
		},
		dockFilter() {
			if (this.currentPort.portid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: []
				};
			} else {
				return {};
			}
		},
		berthFilter() {
			this.userBerthIds = this.$store.getters.getUserBerthIds;
			if (this.currentPort && this.currentPort.portid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'id', op: 'in', data: this.userBerthIds }, // berths disponibles para el usuario
						{ field: 'portid', op: 'eq', data: this.currentPort.portid }, // puerto seleccionado
						{ field: 'isanchorage', op: 'ne', data: true }, // si es fondeadero no se muestra
						{ field: 'type', op: 'ne', data: 6 } // si es Fuera de uso no se muestra
					]
				};
			} else {
				return {};
			}
		},
		versionFilter() {
			if (this.BERTHPLANNER_SELECTORS == 'DOCK') {
				if (this.currentDock && this.currentDock.id) {
					return {
						groups: [],
						groupOp: 'or',
						rules: [{ field: 'dockid', op: 'eq', data: this.currentDock.id }]
					};
				} else {
					return {};
				}
			} else if (this.BERTHPLANNER_SELECTORS == 'BERTH') {
				if (this.currentBerth && this.currentBerth.id) {
					return {
						groups: [],
						groupOp: 'or',
						rules: [{ field: 'berthid', op: 'eq', data: this.currentBerth.id }]
					};
				} else {
					return {};
				}
			}
		},
		itemsToSelectPort() {
			return [{ id: this.currentPort.id }];
		},
		itemsToSelectDock() {
			return [{ id: this.currentDock.id }];
		},
		itemsToSelectBerth() {
			return [{ id: this.currentBerth.id }];
		},
		itemsToSelectVersion() {
			return this.currentVersion && this.currentVersion.id ? [{ id: this.currentVersion.id }] : [{ id: null }];
		},
		selectedStringDate: {
			get() {
				if (!this.selectedDate) return null;

				const dd = (this.selectedDate.getDate() < 10 ? '0' : '') + this.selectedDate.getDate();
				const MM = (this.selectedDate.getMonth() + 1 < 10 ? '0' : '') + (this.selectedDate.getMonth() + 1);
				const yyyy = this.selectedDate.getFullYear();
				return `${yyyy}-${MM}-${dd}`;
			},

			set(v) {
				if (v != null) {
					this.selectedDate = new Date(v);
				}
			}
		},
		selectedStringFormattedDate() {
			if (!this.selectedStringDate) return null;

			const [year, month, day] = this.selectedStringDate.split('-');
			return `${day}/${month}/${year}`;
		},
		currentVersionLoaded() {
			return this.currentVersion && this.currentVersion.id ? true : false;
		}
	},
	watch: {
		currentPort: {
			deep: true,
			handler: function (v) {
				//console.log('currentPort', v);

				if (v == null) {
					this.$store.commit('resetBerthPlannerConfiguration');
				}
			}
		},
		currentDock: {
			deep: true,
			handler: function (v) {
				//console.log('currentDock', v);

				if (v == null) {
					this.currentDock = {};
				}

				if (v && v.id) {
					this.$set(this.currentPort, 'id', v.portid);
					if (this.currentBerth.id) {
						this.currentBerth = {};
					}
				}
			}
		},
		currentBerth: {
			deep: true,
			handler: function (v) {
				//console.log('currentBerth', v);

				if (v == null) {
					this.currentBerth = {};
				}

				if (v && v.id) {
					this.$set(this.currentPort, 'id', v.portid);
					if (this.currentDock.id) {
						this.currentDock = {};
					}
				}
			}
		},
		currentLocation: {
			deep: true,
			handler() {
				if (this.currentBerth && this.currentBerth.berthcode && this.currentBerth.id) {
					trackEvent(
						'input',
						'dropdowm',
						'berth-planner-container-select-berth',
						'berth: ' + this.currentBerth.berthcode + ' id: ' + this.currentBerth.id
					);
				}
			}
		},
		selectedDateModel(v) {
			this.internalDateModel = v;
		},
		currentVersion(v) {
			this.$puiEvents.$emit('berthplanner-toolbar_versionSelected', this.currentVersion);
		},
		currentStatusItems(v) {
			if (JSON.stringify(this.currentStatusModel) != JSON.stringify(v)) {
				this.currentStatusModel = v;
			}
		},
		currentStatusModel() {
			clearTimeout(this.delayTimer);
			this.delayTimer = setTimeout(() => {
				this.$puiEvents.$emit('berthplanner-toolbar_statusSelected', [...this.currentStatusModel]);
			}, 500);
		},
		searchText() {
			clearTimeout(this.searchDelayTimer);
			this.searchDelayTimer = setTimeout(() => {
				this.$puiEvents.$emit('berthplanner-toolbar_searchText', this.searchText);
			}, 500);
		}
	},
	created() {
		this.$puiEvents.$on('berthplanner-container_wrongBollards', (stops) => {
			this.stops = stops;
			this.stopsKey++;
		});

		this.$store.commit('resetBerthPlannerConfiguration');
	},
	mounted() {
		this.loadSavedConfiguration();
	},
	methods: {
		loadSavedConfiguration() {
			const configuration = this.$store.getters.getBerthPlannerConfiguration;
			if (configuration.vesselDialogModel) {
				this.updateSelectedDateModel(configuration.selectedDateModel);
				this.currentLocation = configuration.currentLocation;
				this.searchText = configuration.searchText;
			}
		},
		updateZoom(zoomIncrement) {
			this.$puiEvents.$emit('berthplanner-toolbar_zoomChanged', zoomIncrement);
		},
		updateSelectedDateModel(selectedDateModel) {
			this.$puiEvents.$emit('berthplanner-toolbar_dateSelected', selectedDateModel);
		},
		countPorts() {
			const ports = window.localStorage.getItem('workingPortsId').split(',');
			this.showPortsSelect = ports.length > 1;
		},
		isWeatherValidDate() {
			let date = this.selectedDateModel.startDate;
			return (this.isToday(date) || date > new Date()) && date.getUTCDate() < parseInt(new Date().getUTCDate() + 6);
		},
		isToday(date) {
			return date && date.getDay() == new Date().getDay() && date.getMonth() == new Date().getMonth() && date.getYear() == new Date().getYear();
		},
		optimize() {
			let berths = [];
			if (this.BERTHPLANNER_SELECTORS == 'BERTH') {
				berths.push(this.currentBerth);
			}
			let body = {
				stops: this.vessels.stops.filter((stop) => stop.statusid == 'AC' || stop.statusid == 'AU'),
				berths: berths,
				dock: this.currentDock,
				startDate: this.selectedDateModel.startDate,
				endDate: this.selectedDateModel.endDate
			};
			this.$refs.berthPlannerOptimizationDialog.visibleDialog = true;
			this.$refs.berthPlannerOptimizationDialog.data = body;
		},
		validateCost() {
			let berths = [];
			if (this.BERTHPLANNER_SELECTORS == 'BERTH') {
				berths.push(this.currentBerth);
			}
			let body = {
				stops: this.vessels.stops.filter((stop) => stop.statusid == 'AC' || stop.statusid == 'AU'),
				berths: berths,
				dock: this.currentDock,
				startDate: this.selectedDateModel.startDate,
				endDate: this.selectedDateModel.endDate
			};
			this.$refs.berthPlannerValidateCostDialog.visibleDialog = true;
			this.$refs.berthPlannerValidateCostDialog.data = body;
		},
		waitingEtaCost() {
			if (!this.currentVersion.id) {
				this.$puiNotify.error(this.$puiI18n.t('berthplanner.waitingEtaCost.errorNoVersion'));
				return;
			}
			this.$refs.berthPlannerWaitingEtaCostDialog.visibleDialog = true;
			this.$refs.berthPlannerWaitingEtaCostDialog.versionId = this.currentVersion.id;
		},
		saveVersion() {
			let berths = [];
			berths.push(this.currentBerth);
			let body = {
				stops: this.vessels.stops.filter((stop) => stop.statusid == 'AC' || stop.statusid == 'AU'),
				berths: berths,
				dock: this.currentDock,
				startDate: this.selectedDateModel.startDate,
				endDate: this.selectedDateModel.endDate
			};
			this.$refs.berthPlannerSaveVersionDialog.visibleDialog = true;
			this.$refs.berthPlannerSaveVersionDialog.data = body;
		},
		confirmVersion() {
			if (!this.currentVersion.id) {
				this.$puiNotify.error(this.$puiI18n.t('berthplanner.waitingEtaCost.errorNoVersion'));
				return;
			}
			this.$refs.berthPlannerConfirmVersionDialog.visibleDialog = true;
			this.$refs.berthPlannerConfirmVersionDialog.versionId = this.currentVersion.id;
			this.$refs.berthPlannerConfirmVersionDialog.json = this.currentVersion.berthingjson;
		},
		authorizeStops() {
			let stops = this.vessels.stops.filter(
				(stop) => stop.statusid === 'PA' || stop.statusid === 'PL' || stop.statusid === 'RQ' || stop.statusid === 'AC'
			);

			if (stops.length === 0) {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.resquesteds.authorization.notSelectedAuthorize'));
				return;
			}
			stops.forEach((element) => {
				element.locationberthcode = element.berthcode;
				element.locationberthid = element.berthid;
				element.inibollard = element.bollardinicode;
				element.endbollard = element.bollardendcode;
				element.berthingtypereq = element.berthingtypecode;
				element.entrydate = element.eta ? element.eta : element.startDatesoli;
				element.destinationdate = element.etd ? element.etd : element.enddatesoli;
				element.berthingtype = element.berthingtypecode;
				element.berthingtypename = element.berthingtypename;
				element.portauthority = this.$store.getters.getPortAuthorityId;
				element.portcode = element.portcode;
				element.selected = 1;
			});

			this.stopsToAuthorize = stops;

			this.dialogAuthorize = true;
			//row.isAction = true;
			//this.$puiEvents.$emit('pui-modalDialogForm-authorizeStops-stop-show', row);
		},
		planStops() {
			let stops = this.vessels.stops.filter((stop) => stop.statusid === 'PA' || stop.statusid === 'RQ' || stop.statusid === 'AC');

			if (stops.length === 0) {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.planned.plan.notSelectedPlanning'));
				return;
			}
			stops.forEach((element) => {
				element.locationberthcode = element.berthcode;
				element.locationberthid = element.berthid;
				element.bolcodeini = element.bollardinicode;
				element.bolcodeend = element.bollardendcode;
				element.berthingtypereq = element.berthingtypecode;
				element.etaauth = element.eta ? element.eta : element.startDatesoli;
				element.etdauth = element.etd ? element.etd : element.enddatesoli;
				element.berthingtype = element.berthingtypecode;
				element.berthingtypename = element.berthingtypename;
				element.portauthorityid = this.$store.getters.getPortAuthorityId;
				element.portcode = element.portcode;
			});
			let row = stops;

			row.rows = stops;
			row.isAction = true;

			this.$puiEvents.$emit('pui-modalDialogForm-planStop-stop-show', row);
		}
	}
};
</script>

<style lang="postcss">
.berthplanner-toolbar {
	min-height: 65px;

	& .v-input__control {
		min-height: 40px !important;
		min-width: 135px;
	}
	& .pui-select,
	.searchText {
		& .v-input__control {
			min-width: 290px;
		}
	}
	&__field {
		margin-right: 24px;
	}
}
</style>
